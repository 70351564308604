//import Scroll from './Scroll';
// import Parallax from './parallax';
import Menu from './menu';

$(function ($) {
  new Menu();
  const $sliderfor = $('.js-slider-for');
  $sliderfor.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  });

  $sliderfor.on('afterChange', function (event, slick, currentSlide) {

    $('.js-sliderItem').eq(currentSlide).addClass('is-current').siblings('.js-sliderItem').removeClass('is-current');
  });

  $('.js-sliderItem').on('click', (e) => {
    const target = $(e.currentTarget);
    $sliderfor.slick("slickGoTo", target.index(), false);
    target.addClass('is-current').siblings('.js-sliderItem').removeClass('is-current');
  })

});
